// @flow
import React from 'react';
import Layout from '../../components/layout';
import Image from '../../components/image';
import Link from '../../components/link';
import GlossaryLink from '../../components/glossary-link';
import { withFrontMatter } from '../../components';
import route from '../../routes';
import { graphql } from 'gatsby';
import type { FrontMatter, ImageType } from '../../utils/types';
import CanyonRatingCard from '../../components/canyon-rating-card';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _DSF8406: ImageType,
    _DSF8407: ImageType,
    _DSF8408: ImageType,
    _DSF8411: ImageType,
  },
};

class Blog_2014_09_20_Rockfall_In_Mystery_Canyon extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3BIII" title="Mystery" />
        <p>
          At the 2014 Zion Canyon Rendezvous, I had my canyon leadership
          abilities tested for the first time under a stressful situation.
        </p>
        <p>
          We were in Mystery Canyon, a canyon that is beginner friendly with
          competent leadership. I was with a group of 4 other canyoneers – all
          of whom were fairly new to the sport. There was also a group of three
          canyoneers that day and we had been leapfrogging each other during the
          approach hike and through many of the rappels.
        </p>
        <p>
          We went through the first narrows section, saw clouds were starting to
          form overhead, and realized there was a chance we would see rain in
          the afternoon. We continued down canyon, swam through the lake, went
          up and over the dam, and were at the top of the Mystery Springs rappel
          when the world darkened and the{' '}
          <GlossaryLink>monsoonal rain</GlossaryLink> started pouring in.
        </p>
        <p>
          We made the decision to go back up canyon a little to a section that
          was quite high, had large trees and seemed like a generally safe spot.
          To get to it, though, we had to climb up a{' '}
          <GlossaryLink>dryfall</GlossaryLink> with a log propped up to help.
          All but the last few of us were up when the people up to started
          shouting that the flood waters were coming and we had about 30
          seconds. Instinctively, I ran up to higher ground and waited, waited,
          waited. Nothing is happening. I climbed up the dry fall and saw what I
          guessed they were referring to: a small, shimmering little cascade
          coming down the south face of the canyon. I remember thinking that it
          was significantly less water than the{' '}
          <Link to="http://ropewiki.com/Great_White_Icicle">
            Great White Icicle
          </Link>{' '}
          – like a foot wide and less than an inch deep. We made our way to
          higher ground, pulled out our jackets/space blankets, and started
          waiting.
        </p>
        <p>
          I should note that there was never a point in time that I didn’t feel
          our group was perfectly safe. We had passed the dam, the hard rain
          only lasted a few minutes, and there was plenty of higher ground.
        </p>
        <p>
          Ten or so minutes later the "wall" of water finally came. If I had
          been a mouse stuck at the bottom of the canyon, I would have been
          terrified. It was definitely a wave, but no more than a few inches. It
          crawled slowly along, meticulously filling each pool and crack it came
          across before moving on to the next one. At its highest, I don’t think
          it was ever more than a foot wide in the narrowest sections.
        </p>
        <p>
          After 20 minutes or so it was down to a trickle, and another 20
          minutes after that we figured it wouldn’t get any worse so we started
          back down the canyon.
        </p>
        <p>
          As the last few of us were making our way down the log, I also got to
          experience my first landslide.
        </p>
        <Image
          image={data._DSF8406}
          caption={
            <>
              The place where the rocks broke off - all of the pictures are from
              a{' '}
              <Link to={route.blog_2018_09_20_mystery_revisited}>
                descent 4 years later
              </Link>
            </>
          }
        />
        <p>
          I was at the bottom of the <GlossaryLink>down climb</GlossaryLink>{' '}
          with the log spotting someone coming down when I heard it. At first I
          thought it was more thunder, but quickly realized what it really was.
        </p>
        <Image image={data._DSF8407} caption="Where the landslide ended up" />
        <p>
          Instinctively, I pressed myself up against a big boulder with a small
          overhang, hoping that would be enough to save me if the landslide
          overcame me.
        </p>
        <Image
          image={data._DSF8408}
          caption="Top of the log drop looking down"
        />
        <Image
          image={data._DSF8411}
          caption="Me at the bottom of the drop demonstrating pressing myself against the rock"
        />
        <p>
          My friend Corey was at the top providing meat anchor for a{' '}
          <GlossaryLink>hand line</GlossaryLink> when he heard it. He initially
          thought it was a wall of water. He turned around and intended to start
          running up hill when he saw the rocks starting to fill the canyon
          bottom. Fortunately, he and everybody else was a safe distance from
          the landslide. After everything had settled, I took a quick look and
          saw that the canyon bottom was now a few feet higher. Corey pointed
          out a spot on the cliff three or four hundred feet up where the rocks
          started falling. He noted that this was right above where we had been
          just 10 minutes earlier and if it had fallen then, people would have
          been injured or killed.
        </p>
        <p>
          I no longer felt "perfectly safe" and we hurried out of that section
          and finished the rest of the canyon without incident.
        </p>
        <p>
          I made a few observations that may help others who are in a similar
          situation in the future. We were initially right at the Mystery
          Springs rappel and were all clipped in to the traverse line over the
          cliff. It was high enough and far enough away from the watercourse
          that I felt comfortable, but others didn’t and that’s why we chose to
          move. We ended up at a spot that was quite safe from a potential flash
          flood, but also in an area that has a high potential for landslides.
          The landslide was undoubtedly caused by the rain. Someone smarter than
          me can probably explain the exact cause. At the canyon bottom there
          were lots of landslide-sized rocks – rocks around a foot in diameter
          or less that were part of the cliff at one time and had fallen. Also
          there was a pretty big landslide in the 70s that drastically changed
          Mystery Canyon that was just a little up canyon from where we were.
        </p>
        <p>
          My focus so far has been on the flash flood and landslide but I wanted
          to mention some of the good decisions that contributed to a successful
          day canyoneering. Successful in this sense means no injuries and
          everyone enjoyed themselves.
        </p>
        <ol>
          <li>
            We were all prepared. Everyone had a coat or something they could
            put on to help keep warm. Everyone brought enough food and water for
            the extra time we would spend in the canyon.
          </li>
          <li>
            Corey, though still somewhat new to canyoneering, really performed
            well. He has only descended a handful of canyons, but he knew how
            and where to help without being asked. We were able to{' '}
            <GlossaryLink id="sequence-rappels">sequence</GlossaryLink> the
            first few rappels, he provided{' '}
            <GlossaryLink>meat anchors</GlossaryLink> on down climbs when people
            felt uncomfortable with them, he helped with{' '}
            <GlossaryLink>rope management</GlossaryLink> and carrying ropes, and
            did everything a good canyoneer should do.
          </li>
          <li>
            All of us were able to think rationally through the whole thing. I
            am not a person who makes good decisions under pressure unless I
            have trained for a similar situation. Consequently, I have spent a
            considerable amount of time taking courses, practicing in my free
            time, and researching situations that occur in canyoneering. Because
            of this training, I was able to assess the situation and logically
            deduce that we had little to worry about. We were safe, and I knew
            it. This provided me comfort and I did what I could to ease the
            tension others were experiencing by offering my reassurance and
            being light-hearted about the situation.
          </li>
        </ol>
        <p>
          Anyway, the moral of this story to be prepared for any situation when
          canyoneering, whether that be through training or by some other means.
          Also, when finding higher ground in anticipation of a flash flood, if
          possible, find a place that has a low probability of a landslide.
        </p>
        <p>
          As a side note, this was the third day of the rendezvous. We had all
          been watching the weather and every day thus far had turned out
          beautifully. There were around a dozen groups from the rendezvous who
          went through canyons that day and I am not aware of a single group who
          backed out because of the weather.
        </p>
        <p>
          I also don’t know of any other group who experienced monsoonal rain.
          Talking to a group who did{' '}
          <Link to="http://ropewiki.com/Behunin_Canyon">Behunin</Link> that day,
          they said they got to see a beautiful little trickle of water come
          down through the canyon. The group in{' '}
          <Link to="http://ropewiki.com/Englestead_Hollow">Englestead</Link>{' '}
          didn’t experience anything, either.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('2014-09-20-rockfall-in-mystery-canyon')(
  Blog_2014_09_20_Rockfall_In_Mystery_Canyon
);

export const query = graphql`
  query {
    _DSF8406: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8406.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8407: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8407.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8408: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8408.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8411: file(
      relativePath: {
        eq: "images/blog/2018-09-20-mystery-revisited/_DSF8411.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
